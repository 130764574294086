export const properties = {
    style: {
        primary: {
            main: "#3699E9",
            grey: "#6D6D6D",
        }
    },

    services: {
        service1: "Sumo Robot Workshop",
        service2: "VR Game Design Workshop",
        service3: "3D Printing Workshop",
    }
}